import './owl.carousel';

(function ($) {
  
  // Main Carousel
  $('.main-carousel').owlCarousel({
    autoplay: true,
    loop: true,
    margin: 0,
    nav: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
      }
    }
  });
  
})(jQuery);