/**
 * Booking Form v1.0.0
 */

(function ($) {

  // Booking Form Page
  var bookingForm = $('#booking-form');
  var bookingFormSubmitButton = bookingForm.find(':submit');

  // jQuery AJAX form
  var cf = bookingForm.ajaxForm({
    beforeSubmit: function (arr, $form, options) {
      var filled = arr.every(function (input) {
        return input.value !== '';
      });

      if (filled) {
        bookingFormSubmitButton.attr('disabled', true);
        bookingFormSubmitButton.html('<div class="spinner-border spinner-border-sm text-primary">');
      }
    },

    success: function (response) {
      // Reset submit button
      bookingFormSubmitButton.html('Send').attr('disabled', false);

      // If success
      if (response.success === true) {
        $('#alert-submission', bookingForm).show();
        setTimeout(function () { $('#alert-submission', bookingForm).hide() }, 5000);

        cf.clearForm();

        // Add conversion tag here.
      }

      if (response.success === false && response.data.name) {
        $('input#name', bookingForm).addClass('is-invalid');
        $('#alert-name', bookingForm).text(response.data.name);

        $('#alert-name', bookingForm).show();
        setTimeout(function () {
          $('input#name', bookingForm).removeClass('is-invalid');
          $('#alert-name', bookingForm).text('');
          $('#alert-name', bookingForm).hide();
        }, 5000);
      }

      if (response.success === false && response.data.email) {
        $('input#email', bookingForm).addClass('is-invalid');
        $('#alert-email', bookingForm).text(response.data.email);

        $('#alert-email', bookingForm).show();
        setTimeout(function () {
          $('input#email', bookingForm).removeClass('is-invalid');
          $('#alert-email', bookingForm).text('');
          $('#alert-email', bookingForm).hide();
        }, 5000);
      }

      if (response.success === false && response.data.phone) {
        $('input#phone', bookingForm).addClass('is-invalid');
        $('#alert-phone', bookingForm).text(response.data.phone);

        $('#alert-phone', bookingForm).show();
        setTimeout(function () {
          $('input#phone', bookingForm).removeClass('is-invalid');
          $('#alert-phone', bookingForm).text('');
          $('#alert-phone', bookingForm).hide();
        }, 5000);
      }

      if (response.success === false && response.data.checkin) {
        $('input#checkin', bookingForm).addClass('is-invalid');
        $('#alert-checkin', bookingForm).text(response.data.checkin);

        $('#alert-checkin', bookingForm).show();
        setTimeout(function () {
          $('input#checkin', bookingForm).removeClass('is-invalid');
          $('#alert-checkin', bookingForm).text('');
          $('#alert-checkin', bookingForm).hide();
        }, 5000);
      }

      if (response.success === false && response.data.checkout) {
        $('input#checkout', bookingForm).addClass('is-invalid');
        $('#alert-checkout', bookingForm).text(response.data.checkout);

        $('#alert-checkout', bookingForm).show();
        setTimeout(function () {
          $('input#checkout', bookingForm).removeClass('is-invalid');
          $('#alert-checkout', bookingForm).text('');
          $('#alert-checkout', bookingForm).hide();
        }, 5000);
      }

      if (response.success === false && response.data.adults) {
        $('input#adults', bookingForm).addClass('is-invalid');
        $('#alert-adults', bookingForm).text(response.data.adults);

        $('#alert-adults', bookingForm).show();
        setTimeout(function () {
          $('input#adults', bookingForm).removeClass('is-invalid');
          $('#alert-adults', bookingForm).text('');
          $('#alert-adults', bookingForm).hide();
        }, 5000);
      }

      if (response.success === false && response.data.captcha) {
        $('input#captcha', bookingForm).addClass('is-invalid');
        $('#alert-captcha', bookingForm).text(response.data.captcha);

        $('#alert-captcha', bookingForm).show();
        setTimeout(function () {
          $('input#captcha', bookingForm).removeClass('is-invalid');
          $('#alert-captcha', bookingForm).text('');
          $('#alert-captcha', bookingForm).hide();
        }, 5000);
      }
    },

    error: function (response) {
      console.error(response);

      // Reset submit button
      bookingFormSubmitButton.html('Send').attr('disabled', false);

      $('#alert-submission-failed', bookingForm).show();
      setTimeout(function () { $('#alert-submission-failed', bookingForm).hide() }, 5000);
    },
  });

})(jQuery);
